html,
body {
  padding: 0;
  margin: 0;
}

.body-no-scroll {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

a:focus-visible {
  outline: 0.125rem solid #0070f3;
}

button:focus-visible {
  outline: 0.125rem solid #0070f3;
}

button:focus {
  outline: 0;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "GT-Alpina-Standard-Medium";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/gt-alpina-standard-medium.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/gt-alpina-standard-medium.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/fonts/gt-alpina-standard-medium.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "GT-Alpina-Standard-Medium-Italic";
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/gt-alpina-standard-medium-italic.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/gt-alpina-standard-medium-italic.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("/fonts/gt-alpina-standard-medium-italic.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "GT-Alpina-Standard-Regular";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/gt-alpina-standard-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/gt-alpina-standard-regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/fonts/gt-alpina-standard-regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "GT-Alpina-Standard-Regular-Italic";
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/gt-alpina-standard-regular-italic.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/gt-alpina-standard-regular-italic.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("/fonts/gt-alpina-standard-regular-italic.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "HarmoniaSansProCyr";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/harmoniasansprocyr-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/harmoniasansprocyr-regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/fonts/harmoniasansprocyr-regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "HarmoniaSansProCyr";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/harmoniasansprocyr-semibd.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/harmoniasansprocyr-semibd.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/fonts/harmoniasansprocyr-semibd.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

body * {
  font-family: HarmoniaSansProCyr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Google Maps Autocomplete DropDown Style Overrides (.pac-...) */
/* https://developers.google.com/maps/documentation/javascript/places-autocomplete#style-autocomplete */
.pac-container {
  border-radius: 0.75rem;
  border: 0.0625rem solid #eef5f5;
  box-shadow: 0rem 0.125rem 0.375rem #8aa8b520;
  font-family: HarmoniaSansProCyr;
  margin-top: 0.78125rem;
  padding: 0.5625rem 0;
}

@media screen and (min-width: 61.875rem) {
  .pac-container {
    width: 25.875rem !important;
  }
}

.pac-container::after {
  display: none;
}
.pac-icon {
  display: none;
}
.pac-item {
  border: none;
  color: #192617;
  height: auto;
  margin: 0;
  padding: 0.21875rem 1.125rem;
}

.pac-item:hover {
  background-color: #eafde7;
}

.pac-item span {
  color: #192617;
  font-size: 1rem;
  line-height: 1.3125rem;
}

.pac-matched {
  font-weight: 400;
}

/* Osano */
.osano-cm-dialog {
  border-radius: 0.75rem;
  max-width: 28.125rem;
  border: 0.0625rem solid #eef5f5;
  box-shadow: 0rem 0.125rem 0.375rem rgba(138, 168, 181, 0.1253);
}

.osano-cm-button {
  border-radius: 1.5625rem;
}

.osano-cm-content__message {
  margin-bottom: 0;
  font-weight: 400;
  padding-bottom: 0.625rem;
}

.osano-cm-dialog__content.osano-cm-content::before {
  content: "Some cookies?";
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}

.osano-cm-storage-policy.osano-cm-content__link.osano-cm-link {
  text-decoration: none;
  padding-bottom: 1.875rem;
  color: #ff7817;
}

button.osano-cm-window__widget {
  display: none;
}
